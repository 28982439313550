var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("eSocial - Rubrica"),
      ]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { sm: "4", md: "3" } },
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("table", [
                      _c("tr", [
                        _c("th", { attrs: { width: "145" } }),
                        _c("th"),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Código:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.codRubrIderubrica)),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("CNPJ Empregador:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("cnpj")(_vm.rubric.nrInscIdeempregador)
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Identificador Tab:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.ideTabRubrIderubrica)),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c("br"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("div", [_vm._v("Aplicabilidade:")]),
                      _c("v-textarea", {
                        attrs: { rows: "3", "row-height": "20", readonly: "" },
                        model: {
                          value: _vm.lastRubric.aplicabilidade,
                          callback: function ($$v) {
                            _vm.$set(_vm.lastRubric, "aplicabilidade", $$v)
                          },
                          expression: "lastRubric.aplicabilidade",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("table", [
                      _c("tr", [
                        _c("th", { attrs: { width: "145" } }),
                        _c("th"),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Natureza:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.natRubrDadosrubrica)),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Tipo:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.tpRubrDadosrubrica)),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("CP:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.codIncCPDadosrubrica)),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("IRRF:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.codIncIRRFDadosrubrica)),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("FGTS:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.rubric.codIncFGTSDadosrubrica)),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Início da validade:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("date")(_vm.rubric.iniValidIderubrica, {
                                  showEmpty: true,
                                })
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Fim da validade:")]),
                        _c("td", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("date")(_vm.rubric.fimValidIderubrica, {
                                  showEmpty: true,
                                })
                              )
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v("Observação:")]),
                        _c(
                          "td",
                          [
                            _vm.rubric.observacaoDadosrubrica &&
                            _vm.rubric.observacaoDadosrubrica.length > 20
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "", "max-width": "400" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "strong",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "strong",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.rubric.observacaoDadosrubrica.substr(
                                                          0,
                                                          15
                                                        ) + "..."
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2305730352
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.rubric.observacaoDadosrubrica
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("empty")(
                                          _vm.rubric.observacaoDadosrubrica
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-center pt-5" },
                [
                  _c("br"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnEditRubrica",
                      staticStyle: { "margin-left": "-57%" },
                      attrs: { color: "primary", id: "btnEditLarge" },
                      on: {
                        click: function ($event) {
                          return _vm.edit(_vm.rubricEdit)
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { small: "" } },
                        [_vm._v("mdi-pencil")]
                      ),
                      _vm._v(" Editar "),
                    ],
                    1
                  ),
                  _vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "27%",
                            "margin-top": "-36px",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.conciliar },
                            },
                            [_vm._v(" Conciliar ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { sm: "8", md: "9" } },
            [
              _c(
                "v-alert",
                {
                  attrs: { dismissible: "", color: "warning" },
                  model: {
                    value: _vm.suggestionPending,
                    callback: function ($$v) {
                      _vm.suggestionPending = $$v
                    },
                    expression: "suggestionPending",
                  },
                },
                [
                  _vm._v(
                    "Já existe uma sugestão pendente de aprovação, realize o fluxo de aprovação de rubrica para poder criar outra."
                  ),
                ]
              ),
              _c(
                "v-alert",
                {
                  attrs: { dismissible: "", color: "warning" },
                  model: {
                    value: _vm.notHaveApprovePermission,
                    callback: function ($$v) {
                      _vm.notHaveApprovePermission = $$v
                    },
                    expression: "notHaveApprovePermission",
                  },
                },
                [
                  _vm._v(
                    "Você não tem permissão para editar a rubrica, solicite permissão de aprovador."
                  ),
                ]
              ),
              _c("h4", { staticClass: "mb-3 pl-3" }, [
                _vm._v("Histórico do eSocial"),
              ]),
              _c(
                "v-card",
                [
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.loadingHistoryESocial,
                      headers: _vm.historyESocialHeaders,
                      items: _vm.historyESocialItems,
                      "sort-by": ["dataRegistro"],
                      "sort-desc": [true],
                      "items-per-page": 500,
                      "footer-props": {
                        "items-per-page-options": [10, 25, 50],
                      },
                      dense: "",
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.rubric",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "py-2" }, [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(item.codRubrIderubrica) +
                                    " - " +
                                    _vm._s(item.dscRubrDadosrubrica)
                                ),
                              ]),
                              _c("div", [
                                _vm._v(" Tipo: "),
                                _c("strong", [
                                  _vm._v(_vm._s(item.tpRubrDadosrubrica)),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" Natureza: "),
                                _c("strong", [
                                  _vm._v(_vm._s(item.natRubrDadosrubrica)),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" CP: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(item.codIncCPDadosrubrica) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" IRRF: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(item.codIncIRRFDadosrubrica) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" FGTS: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(item.codIncFGTSDadosrubrica) + " "
                                  ),
                                ]),
                              ]),
                              item.observacaoDadosrubrica
                                ? _c("div", [
                                    _vm._v(" Observação: "),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(item.observacaoDadosrubrica)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.dataRegistro",
                        fn: function ({ item }) {
                          return [
                            _c("div", [
                              _vm._v(" Registro: "),
                              _c("strong", { staticClass: "pl-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.dataRegistro, {
                                      mask: "DD/MM/YYYY HH:mm:ss",
                                    })
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _vm._v(" Início val.:"),
                              _c("strong", { staticClass: "pl-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.iniValidIderubrica, {
                                      showEmpty: true,
                                    })
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _vm._v(" Fim val.:"),
                              _c("strong", { staticClass: "pl-1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.fimValidIderubrica, {
                                      showEmpty: true,
                                    })
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("h4", { staticClass: "mt-8 mb-3 pl-3" }, [
                _vm._v("Alterações"),
              ]),
              _c(
                "v-card",
                [
                  _c("v-data-table", {
                    attrs: {
                      loading: _vm.loadingHistoryESocialSaas,
                      headers: _vm.historyESocialSaasHeaders,
                      items: _vm.historyESocialSaasItems,
                      "sort-by": ["dataRegistro"],
                      "sort-desc": [true],
                      "items-per-page": 500,
                      "footer-props": {
                        "items-per-page-options": [10, 25, 50],
                      },
                      dense: "",
                      "hide-default-footer": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.rubric",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "py-2" }, [
                              _c("strong", [
                                _vm._v(_vm._s(item.dscRubrDadosrubrica)),
                              ]),
                              _c("div", [
                                _vm._v(" Tipo: "),
                                _c("strong", [
                                  _vm._v(_vm._s(item.tpRubrDadosrubrica)),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" Natureza: "),
                                _c("strong", [
                                  _vm._v(_vm._s(item.natRubrDadosrubrica)),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" CP: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(item.codIncCPDadosrubrica) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" IRRF: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(item.codIncIRRFDadosrubrica) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" FGTS: "),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(item.codIncFGTSDadosrubrica) + " "
                                  ),
                                ]),
                              ]),
                              item.aplicabilidade
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(" Aplicabilidade: "),
                                      item.aplicabilidade &&
                                      item.aplicabilidade.length > 35
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                "max-width": "400",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "strong",
                                                          _vm._g(
                                                            _vm._b(
                                                              {},
                                                              "strong",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.aplicabilidade.substr(
                                                                    0,
                                                                    30
                                                                  ) + "..."
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.aplicabilidade)
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("empty")(
                                                  item.aplicabilidade
                                                )
                                              )
                                            ),
                                          ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.observacaoDadosrubrica
                                ? _c(
                                    "div",
                                    [
                                      _vm._v(" Observação: "),
                                      item.observacaoDadosrubrica &&
                                      item.observacaoDadosrubrica.length > 35
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                "max-width": "400",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "strong",
                                                          _vm._g(
                                                            _vm._b(
                                                              {},
                                                              "strong",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.observacaoDadosrubrica.substr(
                                                                    0,
                                                                    30
                                                                  ) + "..."
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.observacaoDadosrubrica
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("empty")(
                                                  item.observacaoDadosrubrica
                                                )
                                              )
                                            ),
                                          ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.dataRegistro",
                        fn: function ({ item }) {
                          return [
                            _c("div", [
                              _vm._v(" Registro:"),
                              _c("br"),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.dataRegistro, {
                                      mask: "DD/MM/YYYY HH:mm:ss",
                                    })
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _vm._v(" Início validade:"),
                              _c("br"),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.iniValidIderubrica, {
                                      showEmpty: true,
                                    })
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", [
                              _vm._v(" Fim validade:"),
                              _c("br"),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("date")(item.fimValidIderubrica, {
                                      showEmpty: true,
                                    })
                                  )
                                ),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.userChange",
                        fn: function ({ item }) {
                          return [
                            _c("div", [
                              _vm._v(" Usuário: "),
                              _c("br"),
                              _c("strong", [
                                _vm._v(_vm._s(item.userChange) + " "),
                              ]),
                            ]),
                            _c("div", [
                              _vm._v(" Justificativa: "),
                              _c("br"),
                              _c("strong", [
                                _vm._v(_vm._s(item.justificativa) + " "),
                              ]),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "item.status",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              _vm._g(
                                {
                                  class: {
                                    "v-chip--clickable":
                                      _vm.rubric.status ===
                                        "PENDENTE_APROVACAO" &&
                                      item.status === "PENDENTE_APROVACAO",
                                  },
                                  attrs: {
                                    id: _vm.rubric.status,
                                    color: "primary",
                                    outlined: "",
                                  },
                                },
                                _vm.rubric.status === "PENDENTE_APROVACAO" &&
                                  item.status === "PENDENTE_APROVACAO"
                                  ? { click: () => _vm.aproveRubrica(item) }
                                  : {}
                              ),
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("estados")(item.status)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "item.actions",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", id: "btnEdit" },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-pencil")])],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("modal-edit-rubric", {
        ref: "modalEditRubric",
        attrs: { "rubric-origin": _vm.rubric },
        on: { success: _vm.recall },
      }),
      _c("modal-approve-rubrica", {
        ref: "modalApproveRubrica",
        attrs: { rubric: _vm.rubricOrigin, suggestion: _vm.suggestion },
        on: { clean: _vm.clean },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }