<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">eSocial - Rubrica</h2>

    <v-row>
      <v-col sm="4" md="3">
        <v-row>
          <v-col cols="12">
            <table>
              <tr>
                <th width="145"></th>
                <th></th>
              </tr>
              <tr>
                <td>Código:</td>
                <td>
                  <strong>{{ rubric.codRubrIderubrica }}</strong>
                </td>
              </tr>
              <tr>
                <td>CNPJ Empregador:</td>
                <td>
                  <strong>{{ rubric.nrInscIdeempregador | cnpj }}</strong>
                </td>
              </tr>
              <tr>
                <td>Identificador Tab:</td>
                <td>
                  <strong>{{ rubric.ideTabRubrIderubrica }}</strong>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <br />

        <v-row>
          <v-col cols="12">
            <div>Aplicabilidade:</div>
            <v-textarea rows="3" row-height="20" readonly v-model="lastRubric.aplicabilidade"> </v-textarea>
          </v-col>
        </v-row>

        <br />

        <v-row>
          <v-col cols="12">
            <table>
              <tr>
                <th width="145"></th>
                <th></th>
              </tr>
              <tr>
                <td>Natureza:</td>
                <td>
                  <strong>{{ rubric.natRubrDadosrubrica }}</strong>
                </td>
              </tr>
              <tr>
                <td>Tipo:</td>
                <td>
                  <strong>{{ rubric.tpRubrDadosrubrica }}</strong>
                </td>
              </tr>
              <tr>
                <td>CP:</td>
                <td>
                  <strong>{{ rubric.codIncCPDadosrubrica }}</strong>
                </td>
              </tr>
              <tr>
                <td>IRRF:</td>
                <td>
                  <strong>{{ rubric.codIncIRRFDadosrubrica }}</strong>
                </td>
              </tr>
              <tr>
                <td>FGTS:</td>
                <td>
                  <strong>{{ rubric.codIncFGTSDadosrubrica }}</strong>
                </td>
              </tr>
              <tr>
                <td>Início da validade:</td>
                <td>
                  <strong>{{ rubric.iniValidIderubrica | date({ showEmpty: true }) }}</strong>
                </td>
              </tr>
              <tr>
                <td>Fim da validade:</td>
                <td>
                  <strong>{{ rubric.fimValidIderubrica | date({ showEmpty: true }) }}</strong>
                </td>
              </tr>
              <tr>
                <td>Observação:</td>
                <td>
                  <v-tooltip
                    v-if="rubric.observacaoDadosrubrica && rubric.observacaoDadosrubrica.length > 20"
                    bottom
                    max-width="400"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <strong v-bind="attrs" v-on="on">
                        {{ rubric.observacaoDadosrubrica.substr(0, 15) + '...' }}
                      </strong>
                    </template>
                    <span>{{ rubric.observacaoDadosrubrica }}</span>
                  </v-tooltip>
                  <strong v-else>
                    {{ rubric.observacaoDadosrubrica | empty }}
                  </strong>
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>

        <div class="text-center pt-5">
          <br />
          <v-btn
            color="primary"
            style="margin-left: -57%"
            class="btnEditRubrica"
            @click="edit(rubricEdit)"
            id="btnEditLarge"
          >
            <v-icon class="mr-2" small>mdi-pencil</v-icon>
            Editar
          </v-btn>
          <div v-if="isAdmin" style="margin-left: 27%; margin-top: -36px">
            <v-btn color="primary" @click="conciliar"> Conciliar </v-btn>
          </div>
        </div>
        <!--<v-alert v-if="suggestionPending" color="yellow">{{ suggestionPending }}</v-alert> -->
      </v-col>
      <v-col sm="8" md="9">
        <v-alert v-model="suggestionPending" dismissible color="warning"
          >Já existe uma sugestão pendente de aprovação, realize o fluxo de aprovação de rubrica para poder criar
          outra.</v-alert
        >
        <v-alert v-model="notHaveApprovePermission" dismissible color="warning"
          >Você não tem permissão para editar a rubrica, solicite permissão de aprovador.</v-alert
        >
        <h4 class="mb-3 pl-3">Histórico do eSocial</h4>

        <v-card>
          <v-data-table
            :loading="loadingHistoryESocial"
            :headers="historyESocialHeaders"
            :items="historyESocialItems"
            :sort-by="['dataRegistro']"
            :sort-desc="[true]"
            :items-per-page="500"
            :footer-props="{
              'items-per-page-options': [10, 25, 50],
            }"
            dense
            hide-default-footer
          >
            <template v-slot:item.rubric="{ item }">
              <div class="py-2">
                <strong>{{ item.codRubrIderubrica }} - {{ item.dscRubrDadosrubrica }}</strong>
                <div>
                  Tipo: <strong>{{ item.tpRubrDadosrubrica }}</strong>
                </div>
                <div>
                  Natureza: <strong>{{ item.natRubrDadosrubrica }}</strong>
                </div>
                <div>
                  CP: <strong>{{ item.codIncCPDadosrubrica }} </strong>
                </div>
                <div>
                  IRRF: <strong>{{ item.codIncIRRFDadosrubrica }} </strong>
                </div>
                <div>
                  FGTS: <strong>{{ item.codIncFGTSDadosrubrica }} </strong>
                </div>
                <div v-if="item.observacaoDadosrubrica">
                  Observação: <strong>{{ item.observacaoDadosrubrica }}</strong>
                </div>
              </div>
            </template>
            <template v-slot:item.dataRegistro="{ item }">
              <div>
                Registro: <strong class="pl-1">{{ item.dataRegistro | date({ mask: 'DD/MM/YYYY HH:mm:ss' }) }}</strong>
              </div>
              <div>
                Início val.:<strong class="pl-1">{{ item.iniValidIderubrica | date({ showEmpty: true }) }}</strong>
              </div>
              <div>
                Fim val.:<strong class="pl-1">{{ item.fimValidIderubrica | date({ showEmpty: true }) }}</strong>
              </div>
            </template>
          </v-data-table>
        </v-card>

        <h4 class="mt-8 mb-3 pl-3">Alterações</h4>

        <v-card>
          <v-data-table
            :loading="loadingHistoryESocialSaas"
            :headers="historyESocialSaasHeaders"
            :items="historyESocialSaasItems"
            :sort-by="['dataRegistro']"
            :sort-desc="[true]"
            :items-per-page="500"
            :footer-props="{
              'items-per-page-options': [10, 25, 50],
            }"
            dense
            hide-default-footer
          >
            <template v-slot:item.rubric="{ item }">
              <div class="py-2">
                <strong>{{ item.dscRubrDadosrubrica }}</strong>
                <div>
                  Tipo: <strong>{{ item.tpRubrDadosrubrica }}</strong>
                </div>
                <div>
                  Natureza: <strong>{{ item.natRubrDadosrubrica }}</strong>
                </div>
                <div>
                  CP: <strong>{{ item.codIncCPDadosrubrica }} </strong>
                </div>
                <div>
                  IRRF: <strong>{{ item.codIncIRRFDadosrubrica }} </strong>
                </div>
                <div>
                  FGTS: <strong>{{ item.codIncFGTSDadosrubrica }} </strong>
                </div>
                <div v-if="item.aplicabilidade">
                  Aplicabilidade:
                  <v-tooltip v-if="item.aplicabilidade && item.aplicabilidade.length > 35" bottom max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <strong v-bind="attrs" v-on="on">
                        {{ item.aplicabilidade.substr(0, 30) + '...' }}
                      </strong>
                    </template>
                    <span>{{ item.aplicabilidade }}</span>
                  </v-tooltip>
                  <strong v-else>{{ item.aplicabilidade | empty }}</strong>
                </div>
                <div v-if="item.observacaoDadosrubrica">
                  Observação:
                  <v-tooltip
                    v-if="item.observacaoDadosrubrica && item.observacaoDadosrubrica.length > 35"
                    bottom
                    max-width="400"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <strong v-bind="attrs" v-on="on">
                        {{ item.observacaoDadosrubrica.substr(0, 30) + '...' }}
                      </strong>
                    </template>
                    <span>{{ item.observacaoDadosrubrica }}</span>
                  </v-tooltip>
                  <strong v-else>{{ item.observacaoDadosrubrica | empty }}</strong>
                </div>
              </div>
            </template>
            <template v-slot:item.dataRegistro="{ item }">
              <div>
                Registro:<br /><strong>{{ item.dataRegistro | date({ mask: 'DD/MM/YYYY  HH:mm:ss' }) }}</strong>
              </div>
              <div>
                Início validade:<br /><strong>{{ item.iniValidIderubrica | date({ showEmpty: true }) }}</strong>
              </div>
              <div>
                Fim validade:<br /><strong>{{ item.fimValidIderubrica | date({ showEmpty: true }) }}</strong>
              </div>
            </template>

            <template v-slot:item.userChange="{ item }">
              <div>
                Usuário: <br /><strong>{{ item.userChange }} </strong>
              </div>
              <div>
                Justificativa: <br /><strong>{{ item.justificativa }} </strong>
              </div>
            </template>

            <template v-slot:item.status="{ item }">
              <v-chip
                :id="rubric.status"
                v-bind:class="{
                  'v-chip--clickable': rubric.status === 'PENDENTE_APROVACAO' && item.status === 'PENDENTE_APROVACAO',
                }"
                color="primary"
                outlined
                v-on="
                  rubric.status === 'PENDENTE_APROVACAO' && item.status === 'PENDENTE_APROVACAO'
                    ? { click: () => aproveRubrica(item) }
                    : {}
                "
              >
                {{ item.status | estados }}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon @click="edit(item)" id="btnEdit">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <modal-edit-rubric ref="modalEditRubric" :rubric-origin="rubric" @success="recall" />
    <modal-approve-rubrica ref="modalApproveRubrica" :rubric="rubricOrigin" :suggestion="suggestion" @clean="clean" />
  </v-main>
</template>

<script>
import DescCodigos from '@/assets/json/descCodigos.json';
import eSocialService from '@/services/eSocialService.js';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ModalRubric',

  components: {
    ModalEditRubric: () => import('./components/ModalEditRubric.vue'),
    ModalApproveRubrica: () => import('./components/ModalApproveRubrica.vue'),
  },

  props: {
    id: {
      required: true,
    },
  },

  data() {
    return {
      ultimoEsocial: {},
      isAdmin: false,
      lastRubrica: '',
      rubricOrigin: '',
      suggestion: '',
      suggestionPending: '',
      show: false,
      loading: false,
      loadingHistoryESocial: false,
      loadingHistoryESocialSaas: false,
      notHaveApprovePermission: false,
      suggestionPending: false,
      rubric: {},
      statusItems: ['PENDENTE_PARAMETRIZACAO', 'PENDENTE_APROVACAO'],

      historyESocialItems: [],
      historyESocialHeaders: [
        { align: 'start', text: 'Rubrica', value: 'rubric', sortable: false },
        { align: 'start', text: 'Data', value: 'dataRegistro', sortable: false },
        { align: 'start', text: 'Movimentação', value: 'operacao', sortable: false },
        //   { align: 'center', text: 'Ações', value: 'editEsocial', sortable: false },
      ],

      historyESocialSaasItems: [],
      historyESocialSaasHeaders: [
        { align: 'start', text: 'Rubrica', value: 'rubric', sortable: false },
        { align: 'start', text: 'Data', value: 'dataRegistro', sortable: false },
        { align: 'start', text: 'Responsável', value: 'userChange', sortable: false },
        { align: 'center', text: 'Status', value: 'status', sortable: false },
        { align: 'center', text: 'Ações', value: 'actions', sortable: false },
      ],
    };
  },

  computed: {
    lastRubric() {
      return this.lastRubrica || {};
    },
    ...mapGetters({
      userRoles: 'user/roles',
    }),
    rubricEdit() {
      if (!this.rubric) return {};
      const rubricEdit = {
        evtTabRubrica: { id: this.rubric.id },
        nrInscIdeempregador: this.rubric.nrInscIdeempregador,
        ideTabRubrIderubrica: this.rubric.ideTabRubrIderubrica,
        codRubrIderubrica: this.rubric.codRubrIderubrica,
        dscRubrDadosrubrica: this.rubric.dscRubrDadosrubrica,
        aplicabilidade: this.rubric.aplicabilidade,
        iniValidIderubrica: this.rubric.iniValidIderubrica,
        observacaoDadosrubrica: this.rubric.observacaoDadosrubrica,
        natRubrDadosrubrica: DescCodigos.natRubr[this.rubric.natRubrDadosrubrica],
        tpRubrDadosrubrica: DescCodigos.tpRubr[this.rubric.tpRubrDadosrubrica],
        codIncCPDadosrubrica: DescCodigos.codIncCP[this.rubric.codIncCPDadosrubrica],
        codIncIRRFDadosrubrica: DescCodigos.codIncIR[this.rubric.codIncIRRFDadosrubrica],
        codIncFGTSDadosrubrica: DescCodigos.codIncFGTS[this.rubric.codIncFGTSDadosrubrica],
        fimValidIderubrica: this.rubric.fimValidIderubrica === 'null' ? '' : this.rubric.fimValidIderubrica,
        status: this.rubric.status,
      };
      return rubricEdit;
    },
  },

  created() {
    this.init();
  },

  methods: {
    clean() {
      this.rubricOrigin = {};
      this.suggestion = {};
      this.init();
    },

    async aproveRubrica(item) {
      let findrole = this.userRoles.filter((n) => n === 'ROLE_ESOCIAL_APPROVER');
      let hasRoleAprove = findrole[0];

      if (hasRoleAprove != undefined) {
        this.rubricOrigin = this.formatDates(cloneDeep(this.ultimoEsocial));
        this.suggestion = this.formatDates(cloneDeep(item));
        this.$refs.modalApproveRubrica.$emit('open');
        this.notHaveApprovePermission = false;
      } else {
        this.notHaveApprovePermission = true;
      }
    },

    formatObject(itemToSave) {
      if (itemToSave.iniValidIderubrica) {
        const fimValidIderubricaSplit = itemToSave.iniValidIderubrica.split('/');
        itemToSave.iniValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
      }
      if (itemToSave.fimValidIderubrica) {
        const fimValidIderubricaSplit = itemToSave.fimValidIderubrica.split('/');
        itemToSave.fimValidIderubrica = `${fimValidIderubricaSplit[1]}-${fimValidIderubricaSplit[0]}`;
      }

      itemToSave.codIncCPDadosrubrica = itemToSave.codIncCPDadosrubrica.substring(0, 2);
      itemToSave.codIncFGTSDadosrubrica = itemToSave.codIncFGTSDadosrubrica.substring(0, 2);
      itemToSave.codIncIRRFDadosrubrica = itemToSave.codIncIRRFDadosrubrica.substring(0, 2);

      itemToSave.tpRubrDadosrubrica = itemToSave.tpRubrDadosrubrica.substring(0, 1);

      return itemToSave;
    },

    formatDates(item) {
      if (item.iniValidIderubrica) {
        const iniValidIderubricaSplit = item.iniValidIderubrica.split('-');
        item.iniValidIderubrica = `${iniValidIderubricaSplit[1]}/${iniValidIderubricaSplit[0]}`;
      }

      if (item.fimValidIderubrica != null) {
        const fimValidIderubricaSplit = item.fimValidIderubrica.split('-');
        item.fimValidIderubrica = `${fimValidIderubricaSplit[1]}/${fimValidIderubricaSplit[0]}`;
      }

      return item;
    },
    async init() {
      this.rubric = await eSocialService.evttabrubrica.get(this.id).then((res) => res.data);

      this.fetchHistory();
      this.fetchHistorySaas();

      this.isAdmin = this.userRoles.filter((role) => role === 'ROLE_ADMIN').length > 0;
    },

    async recall() {
      this.rubric = await eSocialService.evttabrubrica.get(this.id).then((res) => res.data);
      this.fetchHistory();
      this.fetchHistorySaas();
    },

    async fetchHistory() {
      this.loadingHistoryESocial = true;
      try {
        const item = this.rubric;
        let params = {
          params: `codRubrIderubrica:${item.codRubrIderubrica},nrInscIdeempregador:${item.nrInscIdeempregador},ideTabRubrIderubrica:${item.ideTabRubrIderubrica}`,
        };

        let response = await eSocialService.evttabrubrica.searchHistoryEsocial(params);

        let filterResponse = response.data.content;

        let formated = [];

        Object.entries(filterResponse).forEach(([key, entries]) => {
          let keys = [];
          keys.push(key);

          entries.natRubrDadosrubrica = DescCodigos.natRubr[entries.natRubrDadosrubrica];
          entries.tpRubrDadosrubrica = DescCodigos.tpRubr[entries.tpRubrDadosrubrica];
          entries.codIncCPDadosrubrica = DescCodigos.codIncCP[entries.codIncCPDadosrubrica];
          entries.codIncIRRFDadosrubrica = DescCodigos.codIncIR[entries.codIncIRRFDadosrubrica];
          entries.codIncFGTSDadosrubrica = DescCodigos.codIncFGTS[entries.codIncFGTSDadosrubrica];
          entries.fimValidIderubrica = entries.fimValidIderubrica === 'null' ? '' : entries.fimValidIderubrica;
          formated.push(entries);
        });

        formated = sortBy(formated, ['dataRegistro']);
        let pendenteAprovacao = formated.slice(-1);

        this.ultimoEsocial = cloneDeep(pendenteAprovacao[0]);

        this.historyESocialItems = formated;
      } catch (e) {
        console.log(e);
      }
      this.loadingHistoryESocial = false;
    },

    async fetchHistorySaas() {
      this.loadingHistoryESocialSaas = true;
      try {
        const item = this.rubric;
        this.historyESocialSaasItems = [];
        this.allAplicabilidades = [];

        let params = `codRubrIderubrica=${item.codRubrIderubrica}&nrInscIdeempregador=${item.nrInscIdeempregador}&ideTabRubrIderubrica=${item.ideTabRubrIderubrica}`;

        let response = await eSocialService.rubricahistorico.searchRubricaHistory(params);

        if (response.data.length > 0) {
          let historicoRubricas = [];

          Object.entries(response.data).forEach(([key, entries]) => {
            let keys = [];
            keys.push(key);
            //entries['operacao'] = entries.evtTabRubrica.operacao;
            entries.fimValidIderubrica = entries.fimValidIderubrica === 'null' ? '' : entries.fimValidIderubrica;
            entries.natRubrDadosrubrica = DescCodigos.natRubr[entries.natRubrDadosrubrica];
            entries.tpRubrDadosrubrica = DescCodigos.tpRubr[entries.tpRubrDadosrubrica];
            entries.codIncCPDadosrubrica = DescCodigos.codIncCP[entries.codIncCPDadosrubrica];
            entries.codIncIRRFDadosrubrica = DescCodigos.codIncIR[entries.codIncIRRFDadosrubrica];
            entries.codIncFGTSDadosrubrica = DescCodigos.codIncFGTS[entries.codIncFGTSDadosrubrica];
            entries.iniValidIderubrica = entries.iniValidIderubrica;
            historicoRubricas.push(entries);
          });

          historicoRubricas = sortBy(historicoRubricas, ['dataRegistro']);
          let pendenteAprovacao = historicoRubricas.slice(-1);

          this.lastRubrica = cloneDeep(pendenteAprovacao[0]);

          this.historyESocialSaasItems = historicoRubricas;
        } else {
          this.historyESocialSaasItems = [];
        }
      } catch (e) {
        console.log(e);
      }
      this.loadingHistoryESocialSaas = false;
    },

    edit(item) {
      let rubrica = item;

      if (rubrica.status != 'PENDENTE_APROVACAO' || this.rubric.status != 'PENDENTE_APROVACAO') {
        this.suggestionPending = false;
        this.$refs.modalEditRubric.$emit('edit', rubrica);
      } else {
        this.suggestionPending = true;
      }
    },
    conciliar() {
      this.$refs.modalEditRubric.$emit('conciliar', this.lastRubrica);
    },
  },
};
</script>
